import { useEffect, useState } from "react";
import "./App.css";

const first = [
  "Mostly Harmness",
  "MASSPIC",
  "HIGHSTUDIO",
  "Picset",
  "Readalittle",
  "COLDPORT",
  "Realcold",
  "JUMPSTUDIO",
];
const second = [
  "a full-service",
  "an integrated",
  "a 360 degree",
  "an unified",
  "a combined",
  "a mixed",
  "a connected",
  "a united",
];
const third = [
  "development",
  "design",
  "creative",
  "advertising",
  "social selling",
  "snake charming",
  "content",
  "branding",
  "web development",
];
const fourth = [
  "agency",
  "studio",
  "collective",
  "consultant",
  "expert",
  "advisor",
  "specialist",
];
const fifth = [
  "help",
  "empower",
  "enable",
  "allow",
  "permit",
  "grant",
  "hand",
  "support",
];
const sixth = [
  "librarians",
  "snake charmers",
  "engineering leaders",
  "bus conductors",
  "divine beings",
  "startups and scaleups",
  "enterprises",
  "water bodies",
];
const seventh = [
  "transform",
  "plagiarize",
  "formulate",
  "restore",
  "enhance",
  "synthesize",
  "convert",
  "mutate",
  "remodel",
  "transfer",
  "reconstruct",
  "alter",
];
const eighth = [
  "local cookies",
  "data",
  "processes",
  "systems",
  "outlook",
  "creatives",
  "resources",
  "software architecture",
  "existing assets",
];
const ninth = [
  "international cupcakes",
  "an enviable future",
  "meaningful insights",
  "a cyberpunk novel",
  "a recipe for success",
  "a repeatable playbook",
];

const tenth = [
  "proprietary",
  "first-of-its-kind",
  "industry standard",
  "groundbreaking",
  "pathbreaking",
  "transformational",
];

const eleventh = [
  "AI and ML",
  "unpaid interns",
  "jailbroken iPhones",
  "sentient robots",
  "natural language processing",
  "figma templates",
  "pirated Adobe software",
  "outsourced labor",
  "untraceable click farms",
];

const twelfth = [
  "bring together",
  "accelerate",
  "catalyze",
  "solidify",
  "enhance",
  "step up",
  "quicken",
  "stimulate",
  "strengthen",
  "enlarge",
  "increase",
  "intensify",
];

const thirteenth = [
  "cross-channel communications",
  "multi-stakeholders interactions",
  "professional methods",
  "scientific findings",
  "homeopathic prescriptions",
  "self-help books",
  "cross-media interactions",
];

const fourteenth = [
  "a more efficient lemonade stand",
  "a stronger claim to bankruptcy",
  "organizational tech-debt",
  "an unhealthy dependency on us",
  "a strong case for your growth journey",
  "a story for the ages to come",
  "something the likes of which have never been seen before",
  "more of what you need, and none of what you don't",
  "stronger relationships with stakeholders",
];

function App() {
  const [showModal, setShowModal] = useState(false);
  const [selected, setSelected] = useState([]);

  const esc = (e) => {
    if (e.key === "Escape" && showModal) {
      setShowModal(false);
    }
  };

  useEffect(() => {
    console.log(window);
  }, []);

  const randomize = () => {
    setSelected([
      getRandom(second),
      getRandom(third),
      getRandom(fourth),
      getRandom(fifth),
      getRandom(sixth),
      getRandom(seventh),
      getRandom(eighth),
      getRandom(ninth),
      getRandom(tenth),
      getRandom(eleventh),
      getRandom(twelfth),
      getRandom(thirteenth),
      getRandom(fourteenth),
      getRandom(first),
    ]);
  };

  useEffect(() => {
    document.addEventListener("keydown", esc, false);
    randomize();
  }, []);

  const Modal = () => {
    return (
      <div className="modal">
        <div className="modal-header">
          <button onClick={() => setShowModal(false)}>
            <svg
              width="21"
              height="40"
              viewBox="0 0 21 40"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path d="M20.5 1L1.5 20L20.5 39" stroke="black" />
            </svg>
            Go back to the boring corporate agency generator
          </button>
        </div>
        <div className="modal-title">
          Humantic AI <br /> & AE Studio
        </div>
        <div className="modal-text">
          <p>
            If you’ve been at the business end of a vendor evaluation, you know
            it’s near impossible to tell one agency from another. Irrespective
            of their output or quality of service, every agency owner or
            representative sounds the same. Like they’ve been possessed by a
            robot programmed to spit out a sales script.
          </p>
          <p>
            AE studio believes in increasing human agency (or decreasing user
            friction) with every product or experience they build. It wanted to
            extend this philosophy to the sales functions within the company as
            well. To that end, AE studio partnered with Humantic AI to humanize
            every sales interaction, and make every pitch authentic and
            buyer-centric.
          </p>
        </div>
      </div>
    );
  };

  const getRandom = (list) => {
    return list[Math.floor(Math.random() * list.length)];
  };

  return (
    <div className="App">
      {showModal && <Modal />}
      <header>
        <h1>Every Agency Pitch Ever!</h1>
      </header>
      <div className="content">
        <div className="container">
          <div className="combo">
            <div className="image-container">
              <img src="/content.png" />
            </div>
            <div className="text-container">
              {selected[13]} is {selected[0]} {selected[1]} {selected[2]}. We{" "}
              {selected[3]} tenured {selected[4]} {selected[5]} their{" "}
              {selected[6]} into {selected[7]}. Using {selected[8]}{" "}
              {selected[9]}, we {selected[10]} various {selected[11]} to build{" "}
              {selected[12]}.
            </div>
          </div>
          <button onClick={randomize}> Generate More Agency BS </button>

          <div className="bottom-btn top">
            <div>
              Humantic AI <br /> & AE Studio
            </div>
            <button onClick={() => setShowModal(true)}>
              So what makes us different?
            </button>
          </div>
        </div>
        <img src="/paper2.png" className="paper" alt="" />
      </div>
      <div className="bottom-btn bottom">
        <button onClick={() => setShowModal(true)}>
          So what makes us different?
        </button>
        <div>
          Humantic AI <br /> & AE Studio
        </div>
      </div>
    </div>
  );
}

export default App;
